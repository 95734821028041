import { h } from 'preact'
import { Block, Row } from 'jsxstyle/preact'

const App = () => {
  return (
    <Row
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      justifyContent="center"
      alignItems="center"
      color="var(--accent)"
      fontSize="50px"
    >
      Coming soon™
    </Row>
  )
}

export default App
