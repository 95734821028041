import { h, render } from 'preact'
import { Provider } from 'unistore/preact'

import { store } from './store'
import App from './App'

import './index.css'

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('body')
)
